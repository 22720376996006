/**
 * This module exports a set of match related utility functions.
 */
import {BECompetitionDetail} from 'API/types';
import format from 'date-fns/fp/format';
import {EventStatus, MatchGroup, MatchSimple, SearchTerm, Sport} from './types';

/**
 * Reports whether a given match is live or not
 * @param match
 */
export const isLiveMatch = (match: MatchSimple): boolean => match.status === EventStatus.LIVE;

/**
 * Takes an array of matches and returns the lives only
 * @param matches
 */
export const filterLiveMatch = (matches: MatchSimple[]): MatchSimple[] =>
  matches.filter(isLiveMatch);

/**
 * Formats the received date string into the yyyy-MM-dd format
 * @param date
 */
export const parseMatchDate = (date: string): string => {
  const d = new Date(date);

  return format('yyyy-MM-dd', d);
};

/**
 * Takes a search term and tells whether it is a sport or not
 * @param term
 */
export const searchTermIsSport = (term: SearchTerm): boolean =>
  term ? Object.values(Sport).includes(term as Sport) : false;

/**
 * Return grouped matches for use in MatchList with headers
 * @param matches flat array of matches
 */

export const groupMatchesByCompetition = (
  matches?: MatchSimple[],
  competitions?: BECompetitionDetail[]
): Record<string, MatchGroup> => {
  if (!matches || !matches.length || !competitions || !competitions.length) return {};

  const matchesByCompetition: Record<string, MatchGroup> = {};

  matches.forEach(m => {
    const {competitionId, groupingId} = m;

    if (typeof matchesByCompetition[competitionId] === 'undefined') {
      matchesByCompetition[competitionId] = {
        title: m.competition,
        groupings: {
          [groupingId]: {
            title: m.grouping,
            matches: [m],
          },
        },
      };
    } else if (typeof matchesByCompetition[competitionId].groupings[groupingId] === 'undefined') {
      matchesByCompetition[competitionId].groupings[groupingId] = {
        title: m.grouping,
        matches: [m],
      };
    } else {
      matchesByCompetition[competitionId].groupings[groupingId].matches.push(m);
    }
  });

  const collator = new Intl.Collator(['en', 'el']);
  Object.entries(matchesByCompetition).forEach(([cId, {groupings}]) => {
    const sortedGroupings = Object.entries(
      groupings
    ).sort(([, {title: title1}], [, {title: title2}]) => collator.compare(title1, title2));
    matchesByCompetition[cId].groupings = Object.fromEntries(sortedGroupings);
  });

  // sort competitions
  if (competitions && competitions.length) {
    const sortedCompetitions = Object.entries(matchesByCompetition).sort(
      (competition1, competition2) => {
        const order1 = competitions.find(c => c.sportRadarId === competition1[0].replace(/_/g, ':'))
          ?.competitionOrder;
        const order2 = competitions.find(c => c.sportRadarId === competition2[0].replace(/_/g, ':'))
          ?.competitionOrder;
        if (order1 === undefined || order2 === undefined) return 0;

        return order1 - order2;
      }
    );
    return Object.fromEntries(sortedCompetitions);
  }

  return matchesByCompetition;
};

/**
 * //TODO: document this and why
 * @param dateTabsType
 * @param currentDate
 * @param data
 */
export const selectMatches = (
  searchTerm: SearchTerm,
  currentDate: string,
  data: Record<string, MatchSimple[]>,
  competitions?: BECompetitionDetail[]
): Record<string, MatchSimple[]> | null => {
  if (!data) return null;

  if (!searchTerm) {
    return (currentDate in data && {[currentDate]: data[currentDate]}) || null;
  }

  const isSport = Object.values(Sport).includes(searchTerm as Sport);
  const isCompetition = competitions && competitions.some(c => c.sportRadarId === searchTerm);

  // Filter and flatten matches of days of the selected month
  const collator = new Intl.Collator('en', {numeric: true});
  const dates: [string, MatchSimple[]][] = Object.entries(data)
    .filter(([date, matches]) => date.startsWith(currentDate))
    .map(
      ([date, matches]) =>
        [
          date,
          matches.filter(m => {
            /* eslint-disable no-nested-ternary */
            return isSport
              ? m.sport === searchTerm
              : isCompetition
              ? m.competitionId.replace(/_/g, ':') === searchTerm
              : false;
          }),
        ] as [string, MatchSimple[]]
    )
    .filter(([, matches]) => matches.length)
    .sort(([date1], [date2]) => collator.compare(date1, date2));
  return Object.fromEntries(dates);
};

/**
 * // TODO: document this
 * @param term
 */
export const byTerm = (term: SearchTerm) => (item: {name: string}): boolean => item.name === term;

export default {
  byTerm,
  selectMatches,
  searchTermIsSport,
  parseMatchDate,
  isLiveMatch,
  filterLiveMatch,
};
